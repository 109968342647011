<template>
  <div class="page-container therapeutic-plan-page">
    <portal to="page-name">Plano terapêutico</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Plano terapêutico</h1>
      </div>
      <div class="card-body">
        <div class="loading loading-lg" v-if="loading" />
        <template v-else>
          <div class="columns form-group">
            <div class="column col-2 col-sm-12 form-group">
              <label for="code" class="form-label">Código atendimento</label>
              <input type="text" id="code" class="form-input" value="FX5K32N1" readonly>
            </div>
            <div class="column col-5 form-group">
              <label for="patient-name" class="form-label">Paciente</label>
              <input type="text" id="patient-name" class="form-input"
                     v-model="data.patient.name" readonly>
            </div>
            <div class="column col-3 col-sm-12 form-group">
              <label class="form-label">Data de nascimento</label>
              <dx-input-date id="patient-birth-date" class="text-center"
                             v-model="data.patient.birthDate" readonly />
            </div>
            <div class="column col-2 col-sm-12 form-group">
              <label for="patient-gender" class="form-label">Sexo</label>
              <input type="text" id="patient-gender" class="form-input"
                     v-model="data.patient.gender" readonly>
            </div>
          </div>
          <div class="divider" />
          <div class="h6">Itens do plano terapêutico</div>
          <div class="therapeutic-plan-group">
            <div class="therapeutic-plan-item text-gray">
              <fa-icon :icon="['fal', 'info-circle']" class="mr-2"/>Dieta
              <small>(em desenvolvimento)</small>
            </div>
          </div>
          <div class="therapeutic-plan-group">
            <div class="therapeutic-plan-item">
              <fa-icon :icon="['fal', 'info-circle']" class="mr-2"/>Medicamentos e Soluções
            </div>
            <div class="therapeutic-plan-content">
              <table class="table table-hover" v-if="filteredMedicines.length > 0">
                <tbody>
                <tr class="c-hand" v-for="(item, i) in filteredMedicines" :key="i">
                  <td style="width: 40px">{{ i + 1 }}</td>
                  <td style="width: 130px">{{ item.date | date('datetime') }}</td>
                  <td>{{ item.name }}</td>
                  <td style="width: 150px">
                    {{ item.quantity }} x {{ unit.getName(item.unit).toLowerCase() }}
                  </td>
                  <td style="width: 150px">{{ access.getName(item.accessType) }}</td>
                  <td style="width: 150px">
                    {{ discretion.getName(item.discretion) }}
                  </td>
                  <td style="width: 120px">
                    <span v-if="item.useDays === 'continuous'">
                      uso contínuo
                    </span>
                        <span v-else-if="item.useDays">
                      por {{ item.useDays }} dia(s)
                    </span>
                  </td>
                  <td style="width: 100px">
                    {{ item.frequency }} x ao dia
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="therapeutic-plan-group">
            <div class="therapeutic-plan-item">
              <fa-icon :icon="['fal', 'info-circle']" class="mr-2"/>Exames clínicos
            </div>
            <div class="therapeutic-plan-content">
              <table class="table table-hover" v-if="filteredExams.length > 0">
                <tbody>
                <tr class="c-hand" v-for="(item, i) in filteredExams" :key="i">
                  <td style="width: 40px">{{ i + 1 }}</td>
                  <td style="width: 130px">{{ item.date | date('datetime') }}</td>
                  <td style="width: 100px">{{ item.code | tuss }}</td>
                  <td>{{ item.name }}</td>
                  <td style="width: 50px">x{{ item.quantity }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="therapeutic-plan-group">
            <div class="therapeutic-plan-item">
              <fa-icon :icon="['fal', 'info-circle']" class="mr-2"/>Exames laboratoriais
            </div>
            <div class="therapeutic-plan-content">
              <table class="table table-hover" v-if="filteredLaboratories.length > 0">
                <tbody>
                <tr class="c-hand" v-for="(item, i) in filteredLaboratories" :key="i">
                  <td style="width: 40px">{{ i + 1 }}</td>
                  <td style="width: 130px">{{ item.date | date('datetime') }}</td>
                  <td style="width: 100px">{{ item.code | tuss }}</td>
                  <td>{{ item.name }}</td>
                  <td style="width: 50px">x{{ item.quantity }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="therapeutic-plan-group">
            <div class="therapeutic-plan-item">
              <fa-icon :icon="['fal', 'info-circle']" class="mr-2"/>Recomendações
            </div>
            <div class="therapeutic-plan-content">
              <table class="table table-hover">
                <tbody>
                <tr class="c-hand"
                    v-for="(item, i) in filteredRecommendations" :key="i">
                  <td style="width: 40px">{{ i + 1 }}</td>
                  <td style="width: 130px">{{ item.date | date('datetime') }}</td>
                  <td>{{ item.notes }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="therapeutic-plan-group">
            <div class="therapeutic-plan-item text-gray">
              <fa-icon :icon="['fal', 'info-circle']" class="mr-2"/>Enfermagem / SAE
              <small>(em desenvolvimento)</small>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import * as unit from 'src/data/drug-units';
import * as access from 'src/data/drug-accesses';
import * as discretion from 'src/data/discretion-types';
import * as therapeutic from 'src/data/therapeutic-plan-statuses';

export default {
  data() {
    return {
      loading: false,
      unit,
      access,
      discretion,
      therapeutic,
      data: this.blankData(),
    };
  },
  mounted() {
    this.data.id = this.$route.params.id;
    this.load();
  },
  computed: {
    filteredMedicines() {
      const medicines = this.data.prescriptions.filter(({ type }) => type === 'medicine');
      return medicines.reduce((results, medicine) => {
        results.push({
          bundleId: medicine.bundleId,
          type: medicine.type,
          date: medicine.date,
          id: medicine.item.id,
          name: medicine.item.name,
          quantity: medicine.quantity,
          unit: medicine.unit,
          justification: medicine.justification,
          accessType: medicine.accessType,
          startDate: medicine.startDate,
          endDate: medicine.endDate,
          useDays: medicine.useDays,
          discretion: medicine.discretion,
          frequency: medicine.frequency,
        });
        return results;
      }, []);
    },
    filteredExams() {
      const exams = this.data.prescriptions.filter(({ type }) => type === 'exam');

      return exams.reduce((results, exam) => {
        results.push({
          bundleId: exam.bundleId,
          type: exam.type,
          date: exam.date,
          justification: exam.justification,
          indication: exam.indication,
          id: exam.item.id,
          code: exam.item.code,
          name: exam.item.name,
          quantity: exam.quantity,
        });
        return results;
      }, []);
    },
    filteredLaboratories() {
      const exams = this.data.prescriptions.filter(({ type }) => type === 'laboratory');

      return exams.reduce((results, exam) => {
        results.push({
          bundleId: exam.bundleId,
          type: exam.type,
          date: exam.date,
          justification: exam.justification,
          indication: exam.indication,
          id: exam.item.id,
          code: exam.item.code,
          name: exam.item.name,
          quantity: exam.quantity,
        });

        return results;
      }, []);
    },
    filteredRecommendations() {
      return this.data.prescriptions.filter(({ type }) => type === 'recommendation');
    },
  },
  methods: {
    load() {
      this.loading = true;

      this.$http.get(`/hospitalizations/${this.data.id}/therapeutic-plans`)
        .then(({ data }) => {
          this.data = {
            id: data.id,
            patient: {
              id: data.patient.id,
              name: data.patient.name,
              birthDate: data.patient.birthDate,
              gender: data.patient.gender === 'male' ? 'Masculino' : 'Feminino',
            },
            prescriptions: data.prescriptions || [],
          };
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    blankData() {
      return {
        id: '',
        status: '',
        startDate: '',
        patient: {
          id: '',
          name: '',
          birthDate: '',
          gender: '',
        },
        insurance: {
          id: '',
          name: '',
          plan: {
            id: '',
            name: '',
          },
        },
        expense: {
          id: '',
          code: '',
          name: '',
        },
        professional: {
          id: '',
          name: '',
        },
        prescriptions: [],
      };
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
.therapeutic-plan-page {
  .therapeutic-plan-group {
    margin-bottom: $layout-spacing-sm;
    .therapeutic-plan-item {
      background-color: $gray-color-light;
      margin-bottom: $layout-spacing-sm;
      padding: $layout-spacing;
      font-weight: bold;
    }
    .therapeutic-plan-content {
      margin: $layout-spacing-sm 0 $layout-spacing-sm 1.3rem;
      table {
        tr {
          &:last-child {
            td {
              border-bottom: none;
              height: 1rem;
            }
          }
        }
      }
    }
  }
}
</style>
